.App {
  text-align: center;
  filter: grayscale(100%);
}

.main-container {
  display: flex;
  height: 100vh;
}

.chat-area {
  flex: 1;
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.activity-area {
  flex: 1;
  background-color: #e0e0e0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sentence-message-container {
  height: 100px;
  width: 80%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

.sentence-area {
  flex: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
}

.message-area {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
  width: 80%;
}

.word-buttons button {
  padding: 10px;
  font-size: 16px;
  background-color: #808080;
  color: white;
  border: none;
  cursor: pointer;
}

.word-buttons button:hover {
  background-color: #606060;
}

.word-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.message {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  text-align: left;
}

.message {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}

.message.you {
  background-color: #d0d0d0;
  align-self: flex-end;
}

.message.lia {
  background-color: #ffffff;
}

.chat-input {
  display: flex;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.chat-input button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #808080;
  color: white;
  border: none;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #606060;
}
